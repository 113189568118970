<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.89 212.67">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-1"
          d="m70.67,129.55c-.73,0-1.45-.28-2.01-.83L12.96,73.03C4.94,65.01.52,54.34.52,43S4.94,20.99,12.96,12.96C20.99,4.94,31.65.52,42.99.52s22.01,4.42,30.03,12.44l55.7,55.7c1.11,1.11,1.11,2.9,0,4.01-1.11,1.11-2.9,1.11-4.01,0l-55.7-55.7c-6.95-6.95-16.19-10.78-26.02-10.78s-19.07,3.83-26.02,10.78c-6.95,6.95-10.78,16.19-10.78,26.02s3.83,19.07,10.78,26.02l55.7,55.7c1.11,1.11,1.11,2.9,0,4.01-.55.55-1.28.83-2.01.83Z"
        />
        <path
          class="cls-1"
          d="m76.63,131.69c-3.18,0-5.91-.92-7.97-2.97-3.83-3.83-3.9-10.63-.19-19.15,3.35-7.7,9.45-16.19,17.18-23.93,14.56-14.56,34.61-25.46,43.08-16.98h0c3.83,3.83,3.9,10.63.19,19.15-3.35,7.7-9.45,16.19-17.18,23.93-11.03,11.03-25.2,19.96-35.11,19.96Zm44.11-60.32c-6.76,0-19.24,6.44-31.08,18.29-7.24,7.24-12.92,15.12-15.99,22.18-2.63,6.06-3,10.87-1,12.88,4.26,4.26,20.16-2.09,35.05-16.98,7.24-7.24,12.92-15.12,15.99-22.18,2.63-6.06,3.01-10.87,1-12.88-.87-.87-2.24-1.3-3.98-1.3Z"
        />
        <path
          class="cls-1"
          d="m210.22,129.55c-.73,0-1.45-.28-2.01-.83-1.11-1.11-1.11-2.9,0-4.01l55.7-55.7c6.95-6.95,10.78-16.19,10.78-26.02s-3.83-19.07-10.78-26.02c-6.95-6.95-16.19-10.78-26.02-10.78s-19.07,3.83-26.02,10.78l-55.7,55.7c-1.11,1.11-2.9,1.11-4.01,0-1.11-1.11-1.11-2.9,0-4.01l55.7-55.7c8.02-8.02,18.69-12.44,30.03-12.44s22.01,4.42,30.03,12.44c8.02,8.02,12.44,18.69,12.44,30.03s-4.42,22.01-12.44,30.03l-55.7,55.7c-.55.55-1.28.83-2.01.83Z"
        />
        <path
          class="cls-1"
          d="m204.26,131.69c-9.91,0-24.08-8.93-35.11-19.96-14.56-14.56-25.46-34.61-16.98-43.08,8.48-8.47,28.52,2.43,43.08,16.98,7.73,7.73,13.83,16.23,17.18,23.93,3.7,8.52,3.63,15.32-.19,19.15-2.06,2.06-4.79,2.97-7.97,2.97Zm-44.11-60.32c-1.74,0-3.1.43-3.98,1.3-4.27,4.26,2.09,20.16,16.98,35.05,14.89,14.89,30.79,21.25,35.05,16.98h0c2.01-2.01,1.64-6.82-1-12.88-3.07-7.06-8.75-14.94-15.99-22.18-11.84-11.84-24.32-18.29-31.08-18.29Z"
        />
        <path
          class="cls-1"
          d="m150.42,172.11c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
        <path
          class="cls-1"
          d="m164.18,203.69c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
        <path
          class="cls-1"
          d="m140.95,212.14c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
        <path
          class="cls-1"
          d="m162.06,146.67c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
        <path
          class="cls-1"
          d="m121.87,143.56c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
        <path
          class="cls-1"
          d="m124.98,181.57c-6.78,0-12.3-5.52-12.3-12.3s5.52-12.3,12.3-12.3,12.3,5.52,12.3,12.3-5.52,12.3-12.3,12.3Zm0-18.93c-3.65,0-6.63,2.97-6.63,6.63s2.97,6.63,6.63,6.63,6.63-2.97,6.63-6.63-2.97-6.63-6.63-6.63Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #267891;
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.05px;
}
</style>
